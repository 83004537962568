import getVideoId from "get-video-id"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

interface VideoContainerProps {
  title: string
  src: string
  className?: string
}

const VideoContainer: React.FC<VideoContainerProps> = ({
  title,
  src,
  className,
}) => {
  const videoId = getVideoId(src).id

  return (
    <Container className={className} id="video-container">
      <iframe
        loading="lazy"
        title={title}
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${src}><img src=https://img.youtube.com/vi/${videoId}/hqdefault.jpg alt=${title}><span>▶</span></a>`}
        src={src}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Container>
  )
}

export default VideoContainer

import React, { ReactNode } from "react"
import styled from "styled-components"

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 48px;
    line-height: 64px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 64px;
    line-height: 80px;
  }
`

interface H1Props {
  children: ReactNode
  className?: string
}

const H1: React.FC<H1Props> = ({ children, className }) => (
  <StyledH1 className={className}>{children}</StyledH1>
)

export default H1

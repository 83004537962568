import React, { ReactNode } from "react"
import styled from "styled-components"

interface StyledContainerProps {
  columns: number
}

const Container = styled.div<StyledContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: 2rem 0;
  padding-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 4rem 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 8rem 0;
    padding-bottom: 4rem;
  }
`

interface GridContainerProps {
  children: ReactNode
  columns?: number
  id?: string
  className?: string
}

const GridContainer: React.FC<GridContainerProps> = ({
  children,
  columns = 12,
  id,
  className,
}) => (
  <Container id={id} className={className} columns={columns}>
    {children}
  </Container>
)

export default GridContainer

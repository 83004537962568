import React, { ReactNode } from "react"
import styled from "styled-components"

const StyledSubtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.75px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 28px;
    line-height: 40px;
  }
`

interface SubtitleProps {
  children: ReactNode
  className?: string
}

const Subtitle: React.FC<SubtitleProps> = ({ children, className }) => (
  <StyledSubtitle className={className}>{children}</StyledSubtitle>
)

export default Subtitle

import React, { ReactNode } from "react"
import styled from "styled-components"

const StyledH3 = styled.h3`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 28px;
    line-height: 40px;
  }
`

interface H3Props {
  children: ReactNode
  className?: string
}

const H3: React.FC<H3Props> = ({ children, className }) => (
  <StyledH3 className={className}>{children}</StyledH3>
)

export default H3
